//Wizzard
export const WIZZARD_CONSTANTS = {
    DISTANCE_TOP: 100,
    DISTANCE_LEFT: 550,

    WIDTH: 150,
    HEIGHT: 250,

    RIGHT_ARM_TRANSLATE_X: 120,
    RIGHT_ARM_TRANSLATE_Y: 170,
    RIGHT_ARM_ROTATION: '80deg',

    LEFT_ARM_TRANSLATE_X: 90,
    LEFT_ARM_TRANSLATE_Y: 170,
    LEFT_ARM_ROTATION: '2deg',

    MAGIC_WAND_TRANSLATE_X: -85,
    MAGIC_WAND_TRANSLATE_Y: 10,

    BODY_TRANSLATE_X: 50,
    BODY_TRANSLATE_Y: 150,

    ANIMATION_TRESHOLD: 80,
}

export const LIGHTBALL_CONSTANTS = {
    HEIGHT:75,
    WIDTH:75,
    BASE_TOP: 140,
    SHINE_BOTTOM_THREASHOLD: 150,
    SHOOT_TARGET_POSITION: {

    }
}

export const ABOUT_MENU_CONSTANTS = {
    WIDTH: 500,
    HEIGHT: 100,
    LEFT:0,
    TOP:0,
    ITEM: {
        BASE_LEFT: 150,
        HEIGHT: 60,
        WIDTH: 200,
        TOP: 0,
        GAP: 0,
    }
}

export const ABOUT_CONTAINER_CONSTANTS ={
    MENU_ACTIVATION_TRHESHOLD: 80,
    WIDTH: 650
}


export const ABOUT_CONTENT_CONSTANTS = {
    WIDTH: 500,
    HEIGHT: 400,
    LEFT: 0,
    TOP: ABOUT_MENU_CONSTANTS.HEIGHT,
    MARGIN_BOTTOM: 50,
}

export const ABOUT_ANIMATION_CONSTANTS = {
    LIGHTBALL_INCREASE_FACTOR: 5,
    ACTIVATION_ANIMATION_TIME_LIGHT: 400,
    ACTIVATION_ANIMATION_TIME_CONTENT_BORDER: 100,
    ACTIVATION_ANIMATION_TIME_CONTENT: 200,
    DEACTIVATION_ANTIMATION_TIME_CONTENT: 50,
    DEACTIVATION_ANIMATION_TIME_CONTENT_BORDER: 100,
    DEACTIVATION_ANIMATION_TIME_LIGHT: 400,
    SWITCHING_ANIMATION_TIME_CONTENT_OUT: 0,
    SWITCHING_ANIMATION_TIME_CONTENT_BORDER_OUT: 200,
    SWITCHING_ANIMATION_TIME_CONTENT_BORDER_IN: 200,
    SWITCHING_ANIMATION_TIME_CONTENT_IN: 0,
    ACTIVATION_STATUS_SET_DELAY: 5,
    CANCEL_HOVER_ANIMATION_DELAY: 200,

}

export const ABOUT_CARD_CONSTANTS={
    WIDTH: 300,
    HEIGHT: 300,
    LEFT: 100,
    TOP: ABOUT_MENU_CONSTANTS.HEIGHT ,
    ABOUT_PHOTO_WRAPPER:{
        WIDTH: 200,
        HEIGHT: 200,
    }
}

export const ABOUT_CANCEL_CONSTANTS={
    WIDTH: 50,
    HEIGHT: 50,
    LINE_HEIGHT: 6,
}