
export const ANIMATION_TIMINGS = {
    
    INIT_MENU_TIME: 200,
    INIT_LETTER_DELAY: 20,
    INIT_LETTER_TIME: 20,
    INIT_LOGO_TIME: 800,
    INIT_UNDERLINE_TIME: 200,
    INIT_UNDERLINE_EXTRA_DELAY: 200,
    //Logo_time + amountLetters * letter_time + underline_extra_delay
    INIT_UNDERLINE_DELAY: 800 + 20 * 20 + 200,
    INIT_MENU_DELAY: 800 + 20 * 20 + 200,

    START_TIME: 500,
}

export const PAGE_MENU_CONSTANTS = {
    INIT_EXTRA_MARGIN_TOP: 50,
    RUN_WIDTH: 300,
    RUN_MARGIN_TOP: 150,
    RUN_MARGIN_LEFT: 50,
}

export const PAGE_COMPANY_LOGO_CONSTANTS = {
    INIT_HEIGHT: 300,
    INIT_WIDTH: 600,
    RUN_HEIGHT: 100,
    RUN_WIDTH: 300,
    RUN_MARGIN_LEFT: 0,
    RUN_MARGIN_TOP: 0,
}