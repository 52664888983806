import react from 'react';

export const DocumentIcon:react.FC<{}> = () => {


    return(
        <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 490 490">
            <g>
                <path d="M77.788,0v265.111H42.189v139.615h0.001l35.59,35.591L77.788,490h370.023V102.422L345.388,0H77.788z M395.793,389.413
                    H57.501v-108.99h338.292V389.413z M353.022,36.962l57.816,57.804h-57.816V36.962z"/>
                <path d="M172.819,296.665h-3.753h-30.371v75.366h14.303v-24.344h16.845l3.312-0.06c10.303,0,17.152-1.66,20.539-4.965
                    c3.387-3.32,5.077-10.019,5.077-20.113c0-10.154-1.772-17.017-5.324-20.561C189.896,298.429,183.017,296.665,172.819,296.665z
                    M181.627,333.406c-1.75,1.495-5.862,2.243-12.337,2.243h-16.292v-26.946h17.728c6.109,0,9.892,0.763,11.342,2.288
                    c1.458,1.525,2.183,5.473,2.183,11.843C184.251,328.396,183.376,331.91,181.627,333.406z"/>
                <path d="M244.94,296.665h-37.055v75.366h37.496c11.888,0,19.537-2.677,22.916-8.03c3.379-5.353,5.084-17.451,5.084-36.263
                    c0-11.514-2.123-19.574-6.325-24.18C262.84,298.953,255.467,296.665,244.94,296.665z M255.841,355.268
                    c-2.004,3.155-6.43,4.725-13.279,4.725h-20.374v-51.291h21.257c6.662,0,10.901,1.495,12.703,4.516
                    c1.802,3.021,2.707,10.094,2.707,21.204C258.854,345.174,257.853,352.128,255.841,355.268z"/>
                <polygon points="283.82,372.031 298.115,372.031 298.115,341.047 331.91,341.047 331.91,329.009 298.115,329.009 298.115,308.702 
                    333.735,308.702 333.735,296.665 283.82,296.665 	"/>
            </g>
        </svg>
    )
}