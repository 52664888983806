import react from 'react';

export const MonitorIcon:react.FC<{}> = () => {

	return(
		<svg viewBox="0 0 122.88 95.51">
			<path  d="M12.6,26.42h96.77v43.73H85.36c-0.68-0.87-1.52-1.52-2.44-2.04c-2.88-1.61-10.38-2.13-13.46-4.31l0,0 c-0.02-0.03-0.04-0.05-0.06-0.07c-0.69-0.69-1.22-1.47-1.75-2.25c-0.12-0.17-0.23-0.35-0.35-0.51c1.3-1.29,2.3-2.91,3.28-4.53 l2.68-4.41c0.01-0.03,0.02-0.05,0.04-0.08l0,0c0.63-1.42,0.83-2.47,0.64-3.21c-0.17-0.67-0.58-1.08-1.24-1.28l0,0l0.15-6.09 c-0.17-2.48-1-4.35-2.3-5.76c-2.6-2.81-8.11-5.51-11.83-5.13c-0.77,0.08,0.08,1.76-0.66,1.97c-1.17,0.33-2.3,0.83-3.32,1.48 c-1.86,1.19-3.36,2.91-3.97,5.06c-0.14,0.51-0.24,1.01-0.29,1.52c-0.1,2.14-0.04,4.69,0.11,6.71c-0.21,0.08-0.4,0.18-0.57,0.29 c-0.36,0.25-0.63,0.57-0.81,0.94c-0.17,0.36-0.24,0.77-0.23,1.22c0.03,0.96,0.47,2.13,1.33,3.41l2.35,3.73 c0.71,1.13,1.46,2.29,2.32,3.31c-0.21,0.61-0.53,1.49-0.89,2.31c-0.24,0.54-0.48,1.04-0.71,1.38c-3.1,2.17-10.56,2.69-13.43,4.29 c-0.92,0.52-1.76,1.17-2.44,2.04H12.6V26.42L12.6,26.42L12.6,26.42z M8.32,0h106.24c4.58,0,8.32,3.74,8.32,8.32v78.87 c0,4.57-3.74,8.32-8.32,8.32H8.32C3.74,95.51,0,91.77,0,87.19V8.32C0,3.74,3.74,0,8.32,0L8.32,0L8.32,0z M117.97,20.1H5.29V88 c0,1.28,1.02,2.3,2.3,2.3h108.04c1.28,0,2.3-1.03,2.3-2.3V20.1H117.97L117.97,20.1L117.97,20.1z M106.64,7.22 c2.27,0,4.11,1.84,4.11,4.11c0,2.27-1.84,4.11-4.11,4.11s-4.11-1.84-4.11-4.11C102.53,9.06,104.38,7.22,106.64,7.22L106.64,7.22 L106.64,7.22z M78.8,7.22c2.27,0,4.11,1.84,4.11,4.11c0,2.27-1.84,4.11-4.11,4.11c-2.27,0-4.11-1.84-4.11-4.11 C74.69,9.06,76.53,7.22,78.8,7.22L78.8,7.22L78.8,7.22z M92.72,7.22c2.27,0,4.11,1.84,4.11,4.11c0,2.27-1.84,4.11-4.11,4.11 c-2.27,0-4.11-1.84-4.11-4.11C88.61,9.06,90.45,7.22,92.72,7.22L92.72,7.22L92.72,7.22z M66.87,84.74c-1.78,0-3.22-1.57-3.22-3.5 c0-1.94,1.44-3.5,3.22-3.5h39.65c1.78,0,3.22,1.57,3.22,3.5s-1.44,3.5-3.22,3.5H66.87L66.87,84.74L66.87,84.74z M14.96,84.74 c-1.76,0-3.19-1.57-3.19-3.5c0-1.94,1.43-3.5,3.19-3.5h36.76c1.76,0,3.19,1.57,3.19,3.5s-1.43,3.5-3.19,3.5H14.96L14.96,84.74 L14.96,84.74z"/>
		</svg>

	)
}

