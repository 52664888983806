import react from 'react';

export const MailLogo:react.FC<{}> = () => {


	return(
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			viewBox="0 0 66.651 66.651">
			<path d="M66.651,18.177c0-5.33-4.337-9.667-9.667-9.667c-3.192,0-6.019,1.562-7.781,3.955H7.336C3.291,12.465,0,15.756,0,19.802
				v31.002c0,4.045,3.291,7.336,7.336,7.336h47.296c4.046,0,7.337-3.291,7.337-7.336v-24.36C64.769,24.749,66.651,21.683,66.651,18.177
				z M59.969,50.804c0,2.942-2.395,5.336-5.337,5.336H7.336C4.394,56.141,2,53.747,2,50.804V19.802c0-2.942,2.394-5.336,5.336-5.336
				h40.725c-0.478,1.144-0.744,2.397-0.744,3.712c0,2.095,0.677,4.031,1.814,5.616L34.304,38.621c-1.83,1.83-4.809,1.829-6.639,0
				L7.488,18.444c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l20.177,20.177c1.305,1.305,3.02,1.957,4.733,1.957
				s3.429-0.652,4.733-1.957l14.744-14.744c1.721,1.58,4.008,2.553,6.523,2.553c1.042,0,2.043-0.17,2.984-0.477V50.804z M56.984,25.844
				c-4.228,0-7.667-3.439-7.667-7.667s3.439-7.667,7.667-7.667s7.667,3.439,7.667,7.667S61.212,25.844,56.984,25.844z M61.391,15.595
				l-3.89,6.224c-0.146,0.233-0.381,0.396-0.651,0.451c-0.064,0.013-0.131,0.02-0.196,0.02c-0.205,0-0.408-0.063-0.578-0.184
				l-3.228-2.287c-0.45-0.319-0.557-0.943-0.237-1.394c0.318-0.451,0.942-0.558,1.394-0.238l2.363,1.674l3.328-5.325
				c0.292-0.468,0.908-0.611,1.378-0.318C61.541,14.51,61.684,15.127,61.391,15.595z"/>
		</svg>
	)
}