
export const MOBILE_ANIMATION_TIMINGS = {
    MENU_SHOW_TIME: 300,
    MENU_HIDE_TIME: 400,
    MENU_TOGGLE_HIDE_TIME: 300,
    MENU_TOGGLE_HIDE_DELAY: 300,
    MENU_BUTTON_SHOW_TIME: 900, 

    INIT_MENU_TIME: 200,
    INIT_LETTER_DELAY: 20,
    INIT_LETTER_TIME: 20,
    INIT_LOGO_TIME: 800,
    INIT_UNDERLINE_TIME: 200,
    INIT_UNDERLINE_EXTRA_DELAY: 200,
    //Logo_time + amountLetters * letter_time + underline_extra_delay
    INIT_UNDERLINE_DELAY: 800 + 20 * 20 + 200,
    INIT_MENU_DELAY: 800 + 20 * 20 + 200,

    RUN_MENU_CANCEL_HOVER: 100,
    START_TIME: 500,

    DEVELOPMENT_CONTENT_SELECTION_TIME: 1000,
    DEVELOPMENT_CONTENT_DESELECTION_TIME: 200,

}

export const MOBILE_PAGE_MENU_CONSTANTS = {
    INIT_EXTRA_MARGIN_TOP: 50,
    RUN_WIDTH: 250,
    RUN_MARGIN_TOP: 150,
    RUN_MARGIN_LEFT: 0,
}

export const MOBILE_PAGE_MENU_TOGGLE_CONSTANTS = {
   
    WIDTH: 40,
    HEIGHT: 40,
    LINE_HEIGHT: 6,
    SHOW_MENU_RIGHT: 30,
    HIDE_MENU_LEFT: 30,
    TOP: 30,

}

export const MOBILE_PAGE_MENU_BUTTON_CONSTANTS = {
    WIDTH: 40,
    HEIGHT: 20,
    TOP: 30,
    LEFT: 30,
}

export const MOBILE_PAGE_COMPANY_LOGO_CONSTANTS = {
    INIT_HEIGHT: 150,
    INIT_WIDTH: 300,
    RUN_HEIGHT: 100,
    RUN_WIDTH: 250,
    RUN_MARGIN_LEFT: 0,
    RUN_MARGIN_TOP: 0,
}