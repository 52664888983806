export const DEVELOPMENT_CARD_CONSTANTS = {
    WIDTH: 200,
    HEIGHT: 200,
    GAP: 50,
    MARGIN_TOP: 50,
    INNER_MARGIN_TOP: 50,
    TRANSLATE_Y: 0,
    SELECTED_TRANSLATE_Y: -50,
    SELECTED_INNER_MARGIN_TOP: 10,
}

export const DEVELOPMENT_CONTENT_CONSTANTS = {
    WIDTH: 700,
    MARGIN_TOP: 110
}

export const DEVELOPMENT_ANIMATION_TIMINGS = {
    
    CARD_SELECTION_TIME: 500,
    CARD_SELECTION_DELAY: 500,
    CARD_DESELECTION_TIME: 500,
    CARD_DESELECTION_DELAY: 500,
    CARD_START_TIME: 200,
    CARD_START_DELAYSTEP: 150,
    CARD_START_BASEDELAY: 200,
    CARD_HOVER_TIME: 200,

    CONTENT_SELECTION_TIME: 500,
    CONTENT_SELECTION_DELAY: 500,
    CONTENT_DESELECTION_TIME: 500,
    CONTENT_DESELECTION_DELAY: 0,
}
